import PropTypes from 'prop-types';
import Image from 'next/image';
import { cn } from 'utils';

// Utils
import getYoutubeVideoId from '../../../src/utils/getYoutubeVideoId';

// Styles
import styles from './ImageBlock.module.css';

// Components
import YoutubePlayer from '../YoutubePlayer/YoutubePlayer';

function ImageBlock({
  url, description, youtube, sizes, theme, noBackground,
}) {
  return (
    <div className={cn([styles.wrapper, styles[theme], noBackground && styles.noBackground])}>
      <div className={cn([styles.container, youtube ? styles.youtube : ''])}>
        {url && !youtube ? (
          <Image
            loading="lazy"
            fill
            src={url}
            alt={description}
            sizes={sizes || '(max-width: 1024px) 100vw, 75vw'}
          />
        ) : null}
        {youtube
          ? (
            <YoutubePlayer
              videoId={getYoutubeVideoId(youtube)}
            />
          )
          : null}
      </div>
    </div>
  );
}

ImageBlock.propTypes = {
  url: PropTypes.string,
  description: PropTypes.string,
  youtube: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  sizes: PropTypes.string,
  noBackground: PropTypes.bool,
};

ImageBlock.defaultProps = {
  url: '',
  description: '',
  youtube: '',
  theme: 'light',
  sizes: '',
  noBackground: false,
};

export default ImageBlock;
